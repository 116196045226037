/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { SxProps, Theme } from '@mui/material';

export const articleLinksSX: SxProps<Theme> = {
    fontWeight: '600',
    display: 'inline-flex',
    alignItems: 'center',
    '& > svg': {
        height: '12px',
        width: '12px',
        ml: '0.25rem',
    },
};

export const vertDividerSX: SxProps<Theme> = {
	bgcolor: '#D8DBDD !important',
	color: '#D8DBDD !important',
	opacity: '0.6',
};

export const newsAlertsEventsButtonSX: SxProps<Theme> = {
    '& svg': {
        height: '14px',
        width: '14px',
        ml: '0.25rem',
    },
};
