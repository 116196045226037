/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { getSettings, useSettings } from '@/data/Settings';
import { DATA_KEY_NEWS_WIDGET_LIST_BY_ID } from '@/data/constants/dataKey';
import { Cache } from '@/data/types/Cache';
import { getServerSideCommon } from '@/data/utils/getServerSideCommon';
import { transactionsNewsWidget } from 'integration/generated/transactions';
import { ComIbmCommerceNewsWidgetHandler } from 'integration/generated/transactions/data-contracts';
import { GetServerSidePropsContext } from 'next';
import useSWR, { unstable_serialize as unstableSerialize } from 'swr';

const newsWidgetFetcher =
	(pub: boolean, throwError = true) =>
	async (
		storeId: string,
		listName: string
	): Promise<ComIbmCommerceNewsWidgetHandler | undefined> => {
		try {
			return await transactionsNewsWidget(pub).editorialNewsDetail(
				storeId,
				listName ? listName : 'simpleList'
			);
		} catch (e) {
			if (throwError) {
				throw e;
			} else {
				return undefined;
			}
		}
	};

export const getNewsWidget = async (
	cache: Cache,
	context: GetServerSidePropsContext
): Promise<ComIbmCommerceNewsWidgetHandler> => {
	const listName = 'simpleList';
	const settings = await getSettings(cache, context);
	const { storeId, langId } = getServerSideCommon(settings, context);
	const key = unstableSerialize([
		{ storeId: settings.storeId, listName },
		DATA_KEY_NEWS_WIDGET_LIST_BY_ID,
	]);
	const value = cache.get(key) ?? newsWidgetFetcher(false)(storeId, listName as string);
	cache.set(key, value);
	return await value;
};

export const useGetNewsWidget = (listName: string) => {
	const { settings } = useSettings();

	const { data, error, isLoading } = useSWR(
		settings?.storeId && listName
			? [{ storeId: settings.storeId, listName }, DATA_KEY_NEWS_WIDGET_LIST_BY_ID]
			: null,
		async ([{ storeId, listName }]) => newsWidgetFetcher(true)(storeId, listName as string)
	);

	return {
		news: data?.news,
		isLoadingNews: isLoading,
		error,
	};
};
