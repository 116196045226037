import useSWR from 'swr';
import { Cache } from '@/data/types/Cache';
import { DATA_KEY_EVENT_URL } from '../constants/dataKey';

export const getEventsRoute = async (cache : Cache) => {
    cache.set(DATA_KEY_EVENT_URL, process.env.EVENT_URL);
};

export const useEventsURL = () => {
	const { data: eventURL } = useSWR(DATA_KEY_EVENT_URL);
	return {
		eventURL,
	};
};
