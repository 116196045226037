/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { getNewsWidget } from '@/data/Content/NewsWidget';
import { getSettings, useSettings } from '@/data/Settings';
import { DATA_KEY_EVENTS_WIDGET_LIST_BY_ID } from '@/data/constants/dataKey';
import { ContentProps } from '@/data/types/ContentProps';
import { customEvents } from 'integration/generated/custom';
import { ComIbmCommerceRestEventsEventsByTagAndNumHandler } from 'integration/generated/custom/data-contracts';
import { GetServerSidePropsContext } from 'next';
import useSWR, { unstable_serialize as unstableSerialize } from 'swr';
import { Cache } from '@/data/types/Cache';
import { getLocalization } from '@/data/Localization';

const newsEventsFetcher =
	(pub: boolean, throwError = true) =>
	async (
		num: string,
		storeId: string
	): Promise<ComIbmCommerceRestEventsEventsByTagAndNumHandler | undefined> => {
		try {
			return await customEvents(pub).jjkeventsEventsSimpleDetail(storeId, num);
		} catch (e) {
			if (throwError) {
				throw e;
			} else {
				return undefined;
			}
		}
	};

export const getNewsEvents = async ({ cache, context }: ContentProps) => {
	await Promise.all([
		await getLocalization(cache, context.locale || 'en-US', 'NewsEvents'),
		await getLocalization(cache, context.locale || 'en-US', 'NewsArticle'),
	]);

	await getNewsWidget(cache, context);
	await getEventsWidget(cache, context);
};

export const getEventsWidget = async (
	cache: Cache,
	context: GetServerSidePropsContext
): Promise<ComIbmCommerceRestEventsEventsByTagAndNumHandler> => {
	const num = '3';
	const settings = await getSettings(cache, context);
	const key = unstableSerialize([
		{ storeId: settings.storeId, num },
		DATA_KEY_EVENTS_WIDGET_LIST_BY_ID,
	]);
	const value = cache.get(key) ?? newsEventsFetcher(false)(settings.storeId, num as string);
	cache.set(key, value);
	return await value;
};
export const useGetEventsSimple = (num: string) => {
	const { settings } = useSettings();

	const { data, error, isLoading } = useSWR(
		settings?.storeId && num
			? [{ storeId: settings.storeId, num }, DATA_KEY_EVENTS_WIDGET_LIST_BY_ID]
			: null,
		async ([{ storeId, num }]) => newsEventsFetcher(true)(storeId, num as string)
	);

	return {
		eventsBySimple: data?.events,
		eventsBySimpleisLoading: isLoading,
		error,
	};
};
